export default function Timer() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="48"
      viewBox="0 0 46 48"
      fill="none"
    >
      <g filter="url(#filter0_d_187_7075)">
        <path
          d="M21.9972 0.0215015C22.3298 0.0072124 22.6641 0 23 0C35.7025 0 46 10.2975 46 23C46 35.7025 35.7025 46 23 46C22.6641 46 22.3298 45.9928 21.9972 45.9785C20.8937 45.9311 20.0375 44.998 20.0849 43.8945C20.1323 42.7909 21.0654 41.9348 22.1689 41.9822C22.4443 41.994 22.7214 42 23 42C33.4934 42 42 33.4934 42 23C42 12.5066 33.4934 4 23 4C22.7214 4 22.4443 4.00598 22.1689 4.01782C21.0654 4.06523 20.1323 3.20905 20.0849 2.1055C20.0375 1.00195 20.8937 0.0689124 21.9972 0.0215015Z"
          fill="white"
        />
        <path
          d="M15.0745 3.44396C15.5854 4.4233 15.2056 5.63134 14.2263 6.14219C13.7352 6.39837 13.2565 6.67544 12.7916 6.97225C11.8606 7.56661 10.624 7.29368 10.0297 6.36266C9.43529 5.43163 9.70822 4.19506 10.6392 3.6007C11.202 3.24147 11.7815 2.90599 12.3763 2.5957C13.3556 2.08484 14.5637 2.46462 15.0745 3.44396Z"
          fill="white"
        />
        <path
          d="M6.36266 10.0297C7.29368 10.624 7.56661 11.8606 6.97225 12.7916C6.67544 13.2565 6.39837 13.7352 6.14219 14.2263C5.63134 15.2056 4.4233 15.5854 3.44396 15.0745C2.46462 14.5637 2.08484 13.3556 2.5957 12.3763C2.90599 11.7815 3.24147 11.202 3.6007 10.6392C4.19506 9.70822 5.43163 9.4353 6.36266 10.0297Z"
          fill="white"
        />
        <path
          d="M2.1055 20.0849C3.20906 20.1323 4.06523 21.0654 4.01782 22.1689C4.00598 22.4443 4 22.7214 4 23C4 23.2786 4.00598 23.5557 4.01782 23.8311C4.06523 24.9346 3.20905 25.8677 2.1055 25.9151C1.00195 25.9625 0.0689124 25.1063 0.0215015 24.0028C0.0072124 23.6702 0 23.3359 0 23C0 22.6641 0.00721252 22.3298 0.0215017 21.9972C0.0689126 20.8937 1.00195 20.0375 2.1055 20.0849Z"
          fill="white"
        />
        <path
          d="M3.44396 30.9255C4.4233 30.4146 5.63134 30.7944 6.14219 31.7737C6.39837 32.2648 6.67544 32.7435 6.97225 33.2084C7.56661 34.1394 7.29368 35.376 6.36266 35.9703C5.43163 36.5647 4.19506 36.2918 3.6007 35.3608C3.24147 34.798 2.90599 34.2185 2.5957 33.6237C2.08484 32.6444 2.46462 31.4363 3.44396 30.9255Z"
          fill="white"
        />
        <path
          d="M10.0297 39.6373C10.624 38.7063 11.8606 38.4334 12.7916 39.0277C13.2565 39.3246 13.7352 39.6016 14.2263 39.8578C15.2056 40.3687 15.5854 41.5767 15.0745 42.556C14.5637 43.5354 13.3556 43.9152 12.3763 43.4043C11.7815 43.094 11.202 42.7585 10.6392 42.3993C9.70822 41.8049 9.4353 40.5684 10.0297 39.6373Z"
          fill="white"
        />
        <path
          d="M34.9143 16.4342C35.6953 15.6532 35.6953 14.3869 34.9143 13.6058C34.1332 12.8248 32.8669 12.8248 32.0859 13.6058L18.3801 27.3116L13.4943 22.4258C12.7132 21.6448 11.4469 21.6448 10.6659 22.4258C9.88482 23.2069 9.88482 24.4732 10.6659 25.2542L16.9659 31.5542C17.7469 32.3353 19.0132 32.3353 19.7943 31.5542L34.9143 16.4342Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_187_7075"
          x="0"
          y="0"
          width="46"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.157371 0 0 0 0 0.410085 0 0 0 0 0.0763733 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_187_7075"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_187_7075"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
