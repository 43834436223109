export default function Youtube() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      className="hover:scale-105 transition-all cursor-pointer hover:shadow-lg hover:shadow-main/50 rounded-full"
    >
      <rect width="60" height="60" rx="30" fill="#3F3F3F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8373 23.1599C40.1779 23.5111 40.4198 23.9459 40.5386 24.4205C40.8559 26.1792 41.0098 27.9634 40.9986 29.7505C41.0049 31.5108 40.851 33.2681 40.5386 35.0005C40.4198 35.4751 40.1779 35.9099 39.8373 36.2611C39.4966 36.6122 39.0694 36.8673 38.5986 37.0005C36.8786 37.4605 29.9986 37.4605 29.9986 37.4605C29.9986 37.4605 23.1186 37.4605 21.3986 37.0005C20.9374 36.8743 20.5166 36.6313 20.1768 36.295C19.8369 35.9586 19.5896 35.5403 19.4586 35.0805C19.1414 33.3218 18.9874 31.5375 18.9986 29.7505C18.9899 27.9768 19.1438 26.206 19.4586 24.4605C19.5774 23.9859 19.8193 23.5511 20.16 23.1999C20.5006 22.8487 20.9279 22.5937 21.3986 22.4605C23.1186 22.0005 29.9986 22.0005 29.9986 22.0005C29.9986 22.0005 36.8786 22.0005 38.5986 22.4205C39.0694 22.5537 39.4966 22.8087 39.8373 23.1599ZM28.7941 32.4257C28.3274 32.6911 27.748 32.354 27.748 31.8172V27.6839C27.748 27.1471 28.3274 26.8101 28.7941 27.0755L32.4281 29.1421C32.9 29.4105 32.9 30.0907 32.4281 30.3591L28.7941 32.4257Z"
        fill="white"
      />
    </svg>
  );
}
