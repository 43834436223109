export default function Coins() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
    >
      <path
        d="M15.3335 21.167C15.3335 23.467 19.0651 25.3337 23.6668 25.3337C28.2684 25.3337 32.0001 23.467 32.0001 21.167M2.00024 12.8337C2.00024 15.1337 5.73189 17.0004 10.3335 17.0004C12.2102 17.0004 13.9418 16.6904 15.3335 16.167M2.00024 18.667C2.00024 20.967 5.73189 22.8337 10.3335 22.8337C12.2102 22.8337 13.9402 22.5237 15.3335 22.0003M23.6668 18.667C19.0651 18.667 15.3335 16.8004 15.3335 14.5004C15.3335 12.2004 19.0651 10.3337 23.6668 10.3337C28.2684 10.3337 32.0001 12.2004 32.0001 14.5004C32.0001 16.8004 28.2684 18.667 23.6668 18.667Z"
        stroke="white"
        strokeWidth="2.37382"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.00024 6.16699V24.5002C2.00024 26.8002 5.73189 28.6669 10.3335 28.6669C12.2102 28.6669 13.9402 28.3569 15.3335 27.8335M15.3335 27.8335V14.5003M15.3335 27.8335C15.3335 30.1335 19.0651 32.0002 23.6668 32.0002C28.2684 32.0002 32.0001 30.1335 32.0001 27.8335V14.5003M18.6668 11.167V6.16699"
        stroke="white"
        strokeWidth="2.37382"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3333 10.3333C5.73165 10.3333 2 8.46663 2 6.16664C2 3.86666 5.73165 2 10.3333 2C14.9349 2 18.6666 3.86666 18.6666 6.16664C18.6666 8.46663 14.9349 10.3333 10.3333 10.3333Z"
        stroke="white"
        strokeWidth="2.37382"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
