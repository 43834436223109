export default function Ruler() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8295 1.12803C17.3255 -0.37601 14.8869 -0.37601 13.3829 1.12803L1.12803 13.3829C-0.376009 14.8869 -0.37601 17.3255 1.12803 18.8295L3.17051 20.872C4.67454 22.376 7.11307 22.376 8.61711 20.872L20.872 8.61711C22.376 7.11307 22.376 4.67454 20.872 3.17051L18.8295 1.12803ZM14.7445 2.48968C15.4966 1.73766 16.7158 1.73766 17.4678 2.48968L19.5103 4.53216C20.2623 5.28418 20.2623 6.50344 19.5103 7.25546L18.7434 8.02242L17.7499 7.02892C17.3738 6.65291 16.7642 6.65291 16.3882 7.02892C16.0122 7.40493 16.0122 8.01456 16.3882 8.39057L17.3817 9.38408L16.3363 10.4295L13.8985 7.99176C13.5225 7.61575 12.9129 7.61575 12.5369 7.99176C12.1609 8.36776 12.1609 8.9774 12.5369 9.35341L14.9746 11.7912L13.9292 12.8366L12.9357 11.8431C12.5597 11.4671 11.95 11.4671 11.574 11.8431C11.198 12.2191 11.198 12.8287 11.574 13.2047L12.5675 14.1982L11.5221 15.2437L9.08436 12.8059C8.70835 12.4299 8.09871 12.4299 7.7227 12.8059C7.34669 13.1819 7.34669 13.7916 7.7227 14.1676L10.1605 16.6053L9.11503 17.6508L8.12152 16.6572C7.74551 16.2812 7.13588 16.2812 6.75987 16.6572C6.38386 17.0333 6.38386 17.6429 6.75987 18.0189L7.75337 19.0124L7.25546 19.5103C6.50344 20.2623 5.28418 20.2623 4.53216 19.5103L2.48968 17.4678C1.73766 16.7158 1.73766 15.4966 2.48968 14.7445L14.7445 2.48968Z"
        fill="#fff"
      />
    </svg>
  );
}
